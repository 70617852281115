import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/LbpActions';
import { formatDateForDatePicker } from '../util/dates';
import { pageViewGA } from '../services/GoogleAnalytics';
import HeadPage from '../components/HeadPage';
import LbpDiaryAction from '../components/LbpDiary/LbpDiaryAction';
import PromptCard from '../components/Card/PromptCard';
import CodewordsIssue from '../components/LbpDiary/CodewordsIssue';
import LbpActivityLog from '../components/LbpDiary/LbpActivityLog';
import LbpActivityLogAdd from '../components/LbpDiary/LbpActivityLogAdd';
import LbpAddActivityForm from '../components/LbpDiary/LbpAddActivityForm';
import StickyNavigation from '../components/StickyNavigation';
import Loader from '../components/Loader';
import LbpProfileSetupForm from '../components/LbpDiary/LbpProfileSetupForm';
import LbpActivityFormPreview from '../components/LbpDiary/LbpActivityFormPreview';
import Progress from '../components/ProgressBar/Progress';
import ProgressBarCircular from '../components/ProgressBar/ProgressBarCircular';
import Button from '../components/Form/Button';
import { getCookie, setCookie } from '../util/cookie';
import { ToastNotification } from '../components/ToastNotification';
import { GetActivityLogExportPdf } from '../api/GetActivityLogExportPdf';
import { saveAs } from 'file-saver';
class LbpDiaryContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pageType: null,
      codeword: null,
      isLicenceExpiryClosed: false,
      activityForm: {
        showPreview: false,
        formdata: null,
      },
      isLoading: false
    };

    this.handleAddActivitySubmit = this.handleAddActivitySubmit.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    if (getCookie('hideLicenceExpiryCard') === 'true') {
      this.setState({ isLicenceExpiryClosed: true });
    }

    actions.loadLbp();

    pageViewGA('LBP Diary');
    //this.getPdfUrl(); 
  }

  handleLbpDiaryAction(type) {
    switch (type) {
      case 'profile-setup':
        pageViewGA('Lbp Profile Setup');
        break;
      case 'lbp-basics':
        pageViewGA('Lbp Basics');
        break;
      case 'codewords':
        pageViewGA(' Lbp Codewords');
        break;
      case 'activities':
        pageViewGA('Lbp Activities');
        break;
      case 'add-activity':
        pageViewGA('Add Activity');
        break;
      case 'export-activities':
        pageViewGA('Export Activities');
        break;
      default:
        break;
    }

    this.setState({ pageType: type });
    window.scrollTo(0, 0);
  }

  renderLbpBasics() {
    const { LbpBasicsContent } = this.props;
    const title = LbpBasicsContent.Title ? LbpBasicsContent.Title : null;

    const subtitle = LbpBasicsContent.Subtitle
      ? LbpBasicsContent.Subtitle
      : null;

    const description = LbpBasicsContent.Description
      ? LbpBasicsContent.Description
      : null;

    return (
      <div className="article-page">
        <HeadPage
          pageTitle={title}
          pageDescription={subtitle}
          customBackButton={() => this.handleLbpDiaryAction(null)}
          titleBgColor="black"
        />
        <div
          className="article-page__wrapper"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    );
  }

  renderProfileSetup() {
    const {
      LBPNumber,
      LicenceExpiryDate,
      lbpLicenceClasses,
      LicenceClass,
      actions,
    } = this.props;

    const licenceClassOptions = lbpLicenceClasses.map((item) => ({
      name: item.Title,
      value: item.NodeID,
    }));

    const handleProfileSetupSubmit = async (lbpDetails) => {
      const formFieldsValues = {
        LBP: { ...lbpDetails },
      };

      await actions.postLbpDetails(formFieldsValues);

      const goBack = () => {
        this.handleLbpDiaryAction(null);
      };

      ToastNotification(
        'success',
        'Got it!',
        'Your LBP profile has been updated',
        3000,
        goBack,
      );
    };

    return (
      <>
        <HeadPage
          pageTitle="LBP Profile Setup"
          pageDescription="Record learning activities and stay on top of your LBP license requirements."
          customBackButton={() => this.handleLbpDiaryAction(null)}
          titleBgColor="white"
        />
        <LbpProfileSetupForm
          onSubmit={handleProfileSetupSubmit}
          licenceClassOptions={licenceClassOptions}
          lbpNumber={LBPNumber}
          expiryDate={formatDateForDatePicker(LicenceExpiryDate)}
          minDate={formatDateForDatePicker()}
          licenceClass={LicenceClass}
        />
      </>
    );
  }

  renderCodewords(codeword) {
    const {
      LbpCodewords: { PageInfo, Issues },
      actions,
      Codewords,
    } = this.props;

    const issuesArray = Issues || [];
    const pageDescription = PageInfo.Subtitle || '';

    const handleAddToActivityLog = (NodeID) => {
      const params = {
        LBP: {
          Codewords: Codewords !== '' ? `${Codewords},${NodeID}` : `${NodeID}`,
        },
      };

      actions.postAddCodewords(params);
    };

    if (codeword !== null) {
      const { NodeID, Title, Date, Question, Answer, ShowAnswer } = codeword;
      let isAlreadySaved =
        Codewords.split(',').findIndex((id) => Number(id) === NodeID) >= 0;

      const handleShowAnswer = () => {
        let updatedProperties = { ...this.state };
        if (updatedProperties.codeword.ShowAnswer) {
          updatedProperties.codeword.ShowAnswer = false;
        } else {
          updatedProperties.codeword.ShowAnswer = true;
        }
        this.setState({ updatedProperties });
      };

      return (
        <div className="article-page article-page--flex">
          <HeadPage
            pageTitle={Title}
            pageDescription={Date}
            boldSmallDescription={true}
            customBackButton={() => {
              this.setState({ codeword: null });
            }}
            titleBgColor="black"
          />

          <div className="article-page__wrapper article-page__wrapper--flex-main">
            <div dangerouslySetInnerHTML={{ __html: Question }} />
            <button
              className="btn btn--primary-black transform-none article-page--lg-margin-top"
              onClick={() => handleShowAnswer()}
            >
              {codeword.ShowAnswer ? 'Hide Answers' : 'Show Answers'}
            </button>
            {ShowAnswer && (
              <p
                className="article-page--lg-margin-top"
                dangerouslySetInnerHTML={{ __html: Answer }}
              />
            )}
            <p className="article-page--lg-margin-top">
              This article originally published by the Ministry of Business,
              Innovation and Employment.
            </p>
          </div>
          <LbpActivityLogAdd
            title="Finished reading this issue of Codewords?"
            description="We’ll add it to your LBP Activity log."
            handleClick={() => handleAddToActivityLog(NodeID)}
            isDisabled={!codeword.ShowAnswer || isAlreadySaved}
          />
        </div>
      );
    }

    const handleCodewordsClick = (
      NodeID,
      Title,
      Date,
      Question,
      Answer,
      ShowAnswer,
    ) => {
      this.setState({
        pageType: 'codewords',
        codeword: { NodeID, Title, Date, Question, Answer, ShowAnswer },
      });
    };

    return (
      <>
        <HeadPage
          pageTitle="CODEWORDS"
          pageDescription={pageDescription}
          customBackButton={() => this.handleLbpDiaryAction(null)}
          titleBgColor="white"
        />

        {issuesArray.map((issue, i) => (
          <CodewordsIssue
            key={i}
            issueData={issue}
            handleCodewordsClick={handleCodewordsClick}
          />
        ))}
      </>
    );
  }

  renderActivities() {
    const {
      LbpActivitiesLog,
      LbpCodewordsLog,
      Activities,
      ArchivedActivities,
      Codewords,
      ArchivedCodewords,
    } = this.props;

    const activitiesLog = Activities.split(',')
      .filter((id) => Number(id) > 0)
      .map((id) =>
        LbpActivitiesLog.find((activity) => activity.ItemID === Number(id)),
      );

    const codewordsLog = Codewords.split(',')
      .filter((id) => Number(id) > 0)
      .map((id) =>
        LbpCodewordsLog.find((codeword) => codeword.NodeID === Number(id)),
      );

    const activitesArchivedLog = ArchivedActivities.split(',')
      .filter((id) => Number(id) > 0)
      .map((id) =>
        LbpActivitiesLog.find((activity) => activity.ItemID === Number(id)),
      );

    const codewordsArchivedLog = ArchivedCodewords.split(',')
      .filter((id) => Number(id) > 0)
      .map((id) =>
        LbpCodewordsLog.find((codeword) => codeword.ItemID === Number(id)),
      );
    return (
      <>
        <div className="head-page__nav">
          <HeadPage
            pageTitle="Activity Log"
            customBackButton={() => this.handleLbpDiaryAction(null)}
            titleBgColor="white"
          />
          <div>
            {/* {this.renderShareButton()} */}
          </div>
          <div className="head-page__nav__btns">
            <button
              className="add-btn"
              onClick={() => this.handleLbpDiaryAction('add-activity')}
            >
              <i className="fal fa-plus"></i>
            </button>
            <button
              className="add-btn"
              onClick={() => this.handleLbpDiaryAction('export-activities')}
            >
              <i className="far fa-external-link-alt"></i>
            </button>
          </div>
        </div>

        <LbpActivityLog
          activities={activitiesLog}
          archivedActivities={activitesArchivedLog}
          codewords={codewordsLog}
          archivedCodewords={codewordsArchivedLog}
          addAnActivity={() => this.handleLbpDiaryAction('add-activity')}
        />
      </>
    );
  }

  async handleAddActivitySubmit(event, lbpActivityImages, lbpActivityImageURL) {
    const { actions } = this.props;

    event.preventDefault();

    let type = '';
    let data;
    if (event.target[0].checked) type = 'Elective';
    if (event.target[1].checked) type = 'On-the-job';

    if (type === 'Elective') {
      data = {
        Type: type,
        Title: event.target[2].value,
        Description: event.target[3].value,
        Date: event.target[4].value,
        Hours: event.target[6].value,
        KeyLearning: '',
        LBPLicenceExpiryDate: '',
      };
    } else if (type === 'On-the-job') {
      data = {
        Type: type,
        Title: event.target[2].value,
        Description: event.target[3].value,
        KeyLearning: event.target[4].value,
        Date: event.target[5].value,
        Hours: '0',
        LBPLicenceExpiryDate: '',
      };
    }

    const form = new FormData();
    form.append('Activity', JSON.stringify(data));
    for (let i = 0; i < lbpActivityImages.length; i++) {
      form.append('FileUpload', lbpActivityImages[i]);
    }

    await actions.postAddActivity(form);

    this.setState({
      activityForm: {
        showPreview: true,
        formdata: { ...data, lbpActivityImageURL },
      },
    });

    ToastNotification(
      'success',
      ' Nice job!',
      'This activity has been logged 🎉',
      3000,
      () => { },
    );
  }

  renderAddActivity() {
    const {
      activityForm: { showPreview, formdata },
    } = this.state;

    const handleLbpDiaryBackBtnCLick = (pageType) => {
      this.setState({
        activityForm: {
          showPreview: false,
          formdata: null,
        },
      });
      this.handleLbpDiaryAction(pageType);
    };

    return (
      <>
        <HeadPage
          pageTitle="Add Activity"
          hasBackButton={true}
          titleBgColor="white"
          customBackButton={() => handleLbpDiaryBackBtnCLick(null)}
        />
        {!showPreview && (
          <LbpAddActivityForm onSubmit={this.handleAddActivitySubmit} />
        )}
        {showPreview && (
          <LbpActivityFormPreview
            {...formdata}
            handleLbpDiaryAddActivity={() =>
              handleLbpDiaryBackBtnCLick('add-activity')
            }
            handleLbpGoHome={() =>
              handleLbpDiaryBackBtnCLick(null)
            }
          />
        )}
      </>
    );
  }

  renderExportActivities() {
    const { LbpActivitiesLog, LbpCodewordsLog, Activities, Codewords } =
      this.props;

    const activitiesLog = Activities.split(',')
      .filter((id) => Number(id) > 0)
      .map((id) =>
        LbpActivitiesLog.find((activity) => activity.ItemID === Number(id)),
      );

    const codewordsLog = Codewords.split(',')
      .filter((id) => Number(id) > 0)
      .map((id) =>
        LbpCodewordsLog.find((codeword) => codeword.NodeID === Number(id)),
      );

    const handleExportPdfClick = async () => {
      this.setState({
        isLoading: true
      });
      try {
        const pdf = await GetActivityLogExportPdf();
        const pdfFile = new Blob([pdf], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfFile);

        let currentDate = new Date();
        let currentMonthName = currentDate.toLocaleString('default', { month: 'long' });

        saveAs(pdfFile, `LBP_Report_${currentMonthName}`);

        ToastNotification(
          'success',
          'PDF downloaded',
          'Please check your Downloads/ Files section for the PDF',
          3000,
          () => { },
        );

        setTimeout(() => URL.revokeObjectURL(pdfUrl), 20000);
      } catch (ex) {
        ToastNotification(
          'failure',
          'Sorry we had a problem with that!',
          'Something went wrong, we could not download the file. Please try again!',
          5000,
          () => { },
        );
      }
      this.setState({
        isLoading: false
      });
    };

    return (
      <>
        {this.state.isLoading ? <Loader /> :
          <>
            <div className="head-page__nav">
              <HeadPage
                pageTitle="Export Activites"
                customBackButton={() => this.handleLbpDiaryAction(null)}
                titleBgColor="white"
              />

            </div>
            <LbpActivityLog
              activities={activitiesLog}
              codewords={codewordsLog}
              exportActivity={true}
            />
            {activitiesLog.length !== 0 && (
              <Button
                text="Export PDF"
                stickyBtn={true}
                handleSubmit={() => handleExportPdfClick()}
              />
            )}
          </>
        }
      </>
    );
  }

  renderIntro() {
    const {
      LBPNumber,
      Codewords,
      loading,
      ProgressElectiveActivitiesPercenctage,
      ProgressOnTheJobLearningActivitiesPercentage,
      Expiry,
      ProgressPercentage,
      ProgressElectiveActivities,
      ProgressOnTheJobLearningActivities,
      ProgressCodewordsPercentage,
    } = this.props;

    if (loading) return <Loader />;

    const codewordsLength =
      Codewords.trim() === '' ? 0 : Codewords.split(',').length;

    const handleButtonClose = () => {
      setCookie('hideLicenceExpiryCard', 'true', '315360000');
      this.setState({ isLicenceExpiryClosed: true });
    };

    return (
      <>
        <div className="head-page__nav">
          <HeadPage
            pageTitle="LBP Diary"
            mainHeading={true}
            hasBackButton={false}
          />
          <button
            className="add-btn"
            onClick={() => this.handleLbpDiaryAction('add-activity')}
          >
            <i className="fal fa-plus"></i>
          </button>
        </div>
        {/*Licence Progress start*/}
        {LBPNumber !== '' && (
          <div className="lbp-licence">
            <div className="lbp-licence__wrapper">
              <div className="lbp-licence__heading">
                <HeadPage
                  pageTitle="Licence Progress"
                  hasBackButton={false}
                  titleBgColor={'white'}
                />
              </div>
              <div className="lbp-licence__menu-items">
                <div className="lbp-licence__menu-items--elective">
                  <div className="lbp-licence__menu-items--elective--text">
                    <p>Elective activities</p>
                    <p>{ProgressElectiveActivities}</p>
                  </div>
                  <div className="lbp-licence__progressbar">
                    <Progress
                      percentage={ProgressElectiveActivitiesPercenctage}
                      variant="progress-red"
                    />
                  </div>
                </div>
                <div className="lbp-licence__menu-items--learning">
                  <div className="lbp-licence__menu-items--learning--text">
                    <p>On-the-job learning</p>
                    <p>{ProgressOnTheJobLearningActivities}</p>
                  </div>
                  <div className="lbp-licence__progressbar">
                    <Progress
                      percentage={ProgressOnTheJobLearningActivitiesPercentage}
                      variant="progress-yellow"
                    />
                  </div>
                </div>
                <div className="lbp-licence__menu-items--codewords">
                  <div className="lbp-licence__menu-items--codewords-text">
                    <p>Codewords</p>
                    <p>{codewordsLength}</p>
                  </div>
                  <div className="lbp-licence__progressbar">
                    <Progress
                      percentage={ProgressCodewordsPercentage}
                      variant="progress-orange"
                    />
                  </div>
                </div>
                <div className="lbp-licence__progressbar-circular">
                  <ProgressBarCircular
                    percentage={
                      isNaN(ProgressPercentage) ? 0 : ProgressPercentage
                    }
                  />
                </div>
                {!this.state.isLicenceExpiryClosed && (
                  <div className="lbp-licence__expiry">
                    <div className="lbp-licence__expiry-icon">
                      <i className="fas fa-info-circle"></i>
                    </div>
                    <div className="lbp-licence__expiry-text">
                      <h4>{Expiry}</h4>
                    </div>
                    <button
                      className="lbp-licence__expiry-btn"
                      onClick={handleButtonClose}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          // Licence Progress end
        )}
        {LBPNumber === '' && (
          <div className="u-margin-top">
            <PromptCard
              heading="LBP profile"
              description="Record learning activities and stay on top of your LBP license requirements."
              handleNextClick={() => this.handleLbpDiaryAction('profile-setup')}
            />
          </div>
        )}

        <div className="lbp-diary-action--top-margin">
          {LBPNumber !== '' && (
            <>
              <LbpDiaryAction
                title="Your LBP Details"
                description="Learning activities"
                handleClick={() => this.handleLbpDiaryAction('profile-setup')}
              />
              <LbpDiaryAction
                title="Activity Log"
                description="View and export activites"
                handleClick={() => this.handleLbpDiaryAction('activities')}
              />
              <LbpDiaryAction
                title="Codewords"
                description="Mandatory reading for LBPs"
                handleClick={() => this.handleLbpDiaryAction('codewords')}
              />
            </>
          )}

          <LbpDiaryAction
            title="LBP Basics"
            description="Description of feature"
            handleClick={() => this.handleLbpDiaryAction('lbp-basics')}
          />
        </div>
      </>
    );
  }

  render() {
    const { pageType, codeword } = this.state;

    return (
      <>
        {!pageType && this.renderIntro()}

        {
          /* Profile Setup */ pageType === 'profile-setup' &&
          this.renderProfileSetup()
        }

        {/* LBP Basics */ pageType === 'lbp-basics' && this.renderLbpBasics()}

        {
          /* LBP Codewords */ pageType === 'codewords' &&
          this.renderCodewords(codeword)
        }

        {
          /* LBP Activities */ pageType === 'activities' &&
          this.renderActivities()
        }

        {
          /* LBP Activities */ pageType === 'add-activity' &&
          this.renderAddActivity()
        }

        {
          /* Export Activities */ pageType === 'export-activities' &&
          this.renderExportActivities()
        }

        <StickyNavigation activeTabName="lbp" />
      </>
    );
  }
}

LbpDiaryContainer.propTypes = {
  loading: PropTypes.bool,
  Expiry: PropTypes.string,
  ProgressPercentage: PropTypes.number,
  ProgressCodewordsPercentage: PropTypes.number,
  ProgressElectiveActivities: PropTypes.string,
  ProgressOnTheJobLearningActivities: PropTypes.string,
  ProgressOnTheJobLearningActivitiesPercentage: PropTypes.number,
  Codewords: PropTypes.string,
  LBPNumber: PropTypes.string,
  LicenceClass: PropTypes.number,
  LicenceExpiryDate: PropTypes.string,
  LbpBasicsContent: PropTypes.object,
  LbpActivitiesLog: PropTypes.array,
  LbpCodewords: PropTypes.object,
  LbpCodewordsLog: PropTypes.array,
  Activities: PropTypes.string,
  ArchivedActivities: PropTypes.string,
  ArchivedCodewords: PropTypes.string,
  LbpCustomerTypes: PropTypes.array,
  lbpLicenceClasses: PropTypes.array,
  ProgressElectiveActivitiesPercenctage: PropTypes.number,
  actions: PropTypes.object,
  pageType: PropTypes.string,
};

LbpDiaryContainer.defaultProps = {
  loading: true,
  LBPNumber: '',
  LicenceClass: null,
  LicenceExpiryDate: ' ',
  LbpBasicsContent: {},
  LbpActivitiesLog: [],
  LbpCodewords: {},
  LbpCodewordsLog: [],
  Activities: '',
  ArchivedActivities: '',
  ArchivedCodewords: '',
  Codewords: '',
  LbpCustomerTypes: [],
  lbpLicenceClasses: [],
};

const mapStateToProps = (state) => {
  const {
    lbp: {
      loading,
      LbpBasicsContent,
      LbpActivitiesLog,
      LbpCodewords,
      LbpCodewordsLog,
      lbpLicenceClasses,
    },
    account: {
      profile: { LBP },
    },
  } = state;

  return {
    loading,
    LbpBasicsContent,
    LbpActivitiesLog,
    LbpCodewords,
    LbpCodewordsLog,
    lbpLicenceClasses,
    ...LBP,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LbpDiaryContainer);
